import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { getPaginatedDataDownlinks } from '@/data/admin/dataDownlink'

const DEFAULT_REFETCH_INTERVAL =
  import.meta.env.VITE_ADMIN_DATAPOINTS_POLLING_INTERVAL || 30000

function useAdminDataDownlinks(
  imei?: string | null,
  email?: string | null,
  take?: number,
  page?: number,
  from?: Date,
  to?: Date,
  includeDeleted?: boolean | null,
  refetchInterval?: number,
) {
  const abortController = new AbortController()

  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: [
      'DataDownlinks',
      take,
      page,
      imei,
      email,
      from,
      to,
      includeDeleted,
    ],
    queryFn: async () =>
      getPaginatedDataDownlinks(
        abortController.signal,
        imei,
        email,
        take,
        page,
        from,
        to,
        includeDeleted,
      ),
    placeholderData: keepPreviousData,
    refetchInterval: refetchInterval ? Number(refetchInterval) : Number(DEFAULT_REFETCH_INTERVAL),
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return { data, isLoading, refetch, isRefetching }
}

export default useAdminDataDownlinks
