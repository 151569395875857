import { useState, useEffect, useRef } from 'react'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import { AIUploadProps, ChatMessage as ChatMessageType } from './types'
import { ChatMessage } from './ChatMessage'
import { processFileForAnalysis, ConversionProgress } from './utils/file-handling'
import { analyzeSandReport, SandAnalysisData, ClaudeContent } from '@/data/sand-analysis'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Loader2 } from 'lucide-react'

const DISCLAIMER_MESSAGE = (
    <div className="space-y-4">
        <div>
            <h2 className="font-semibold text-lg mb-2 flex items-center gap-2">
                <span>Important Notice</span>
            </h2>
            <p className="text-sm">
                The analysis provided is for reference only. You must verify all data with your supplier or laboratory before making any decisions.
            </p>
        </div>

        <div>
            <h4 className="font-medium mb-2">
                Key Points:
            </h4>
            <ul className="text-sm space-y-1">
                <li className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>We take no responsibility for the accuracy of the curve analysis</span>
                </li>
                <li className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>The recommended sand curve intervals are based on experience and standard gradation</span>
                </li>
                <li className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>These recommendations are not guaranteed to work in all situations</span>
                </li>
            </ul>
        </div>

        <p className="text-sm">
            By uploading a file, you acknowledge and accept these terms.
        </p>
    </div>
)

function formatAnalysisResults(text: string, data: SandAnalysisData) {
    return (
        <div className="space-y-6">
            {text && <p className="text-sm text-muted-foreground whitespace-pre-wrap">{text}</p>}

            {/* Sample Info with Use Data Button */}
            <div className="flex items-start justify-between gap-4">
                <div className="flex-1 text-sm">
                    <div className="grid grid-cols-[auto_1fr_auto_1fr] gap-x-4 gap-y-1">
                        <span className="text-muted-foreground">Material:</span>
                        <span>{data.sample_info.material_name}</span>
                        <span className="text-muted-foreground">Company:</span>
                        <span>{data.sample_info.company}</span>
                        <span className="text-muted-foreground">Test Date:</span>
                        <span>{new Date(data.sample_info.test_date).toLocaleDateString()}</span>
                        <span className="text-muted-foreground">Total Mass:</span>
                        <span>{data.sample_info.total_mass_g}g</span>
                    </div>
                </div>
            </div>

            {/* Warnings/Notes - Shown earlier with clear severity indicators */}
            {data.warnings.length > 0 && (
                <div className="space-y-2">
                    <h4 className="font-medium text-blue-500">Analysis Notes</h4>
                    <ul className="text-sm space-y-2">
                        {data.warnings.map((warning: SandAnalysisData['warnings'][0], i: number) => (
                            <li key={i} className="flex items-start gap-3">
                                <span className={cn(
                                    "flex h-5 w-5 shrink-0 items-center justify-center rounded-full text-xs",
                                    warning.severity === 'error' && "bg-red-100 text-red-600",
                                    warning.severity === 'warning' && "bg-yellow-100 text-yellow-600",
                                    warning.severity === 'info' && "bg-blue-100 text-blue-600"
                                )}>
                                    {warning.severity === 'error' ? '!' :
                                        warning.severity === 'warning' ? '⚡' : 'i'}
                                </span>
                                <span className={cn(
                                    "flex-1",
                                    warning.severity === 'error' && "text-red-600",
                                    warning.severity === 'warning' && "text-yellow-600"
                                )}>{warning.message}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {/* Measurements */}
            <div>
                <h4 className="font-medium text-blue-500 mb-2">Measurements</h4>
                <div className="text-sm">
                    <table className="w-full">
                        <thead>
                            <tr className="text-left border-b">
                                <th className="pb-1">Mesh</th>
                                <th className="pb-1">Diameter (mm)</th>
                                <th className="pb-1">Retained (g)</th>
                                <th className="pb-1">Source</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.measurements.map((m: SandAnalysisData['measurements'][0], i: number) => (
                                <tr key={i} className="border-b last:border-0">
                                    <td className="py-1">{m.meshNumber}</td>
                                    <td className="py-1">{m.diameterMm}</td>
                                    <td className="py-1">{m.retainedGrams}</td>
                                    <td className="py-1 text-xs text-muted-foreground">
                                        {m.isCalculated && '⚡ '}{m.dataSource.replace('_', ' ')}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

const INITIAL_MESSAGES: ChatMessageType[] = [
    {
        id: 'disclaimer',
        type: 'system',
        content: DISCLAIMER_MESSAGE,
        timestamp: new Date(),
    },
    {
        id: 'upload-prompt',
        type: 'upload',
        content: <h3 className="font-semibold text-md mb-2 flex items-center gap-2">
            <span>Please upload or take a photo of your sand analysis report</span>
        </h3>,
        timestamp: new Date(),
    }
]

export function AIUploadModal({ open, onOpenChange, onDataUse, isDeveloperMode }: AIUploadProps) {
    const [messages, setMessages] = useState<ChatMessageType[]>(INITIAL_MESSAGES)
    const [isProcessing, setIsProcessing] = useState(false)
    const messagesEndRef = useRef<HTMLDivElement>(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    useEffect(() => {
        scrollToBottom()
    }, [messages])

    const handleFileSelect = async (file: File) => {
        try {
            setIsProcessing(true)


            // Add Claude analysis message
            const analysisMessageId = 'analysis-' + Date.now()
            setMessages(prev => [...prev, {
                id: analysisMessageId,
                type: 'system',
                content: <h3 className="font-semibold text-md mb-2 flex items-center gap-2">
                    <span>We're analyzing your file... </span> <Loader2 className="animate-spin" />
                </h3>,
                timestamp: new Date(),
                metadata: { loading: true }
            }])

            // Process the file
            const result = await processFileForAnalysis(file)
            

            // Send to Claude API for analysis
            const analysisResult = await analyzeSandReport(result.base64)

            const text = analysisResult.find(c => c.type === 'text')?.text
            const data = analysisResult.find(c => c.type === 'tool_use')?.input

            // First show the explanation text
            // const explanation = analysisResult.find(c => c.type === 'text')?.text
            // let messagesToAdd: ChatMessageType[] = []
            // if (explanation) {
            //     messagesToAdd.push({
            //         id: 'explanation-' + Date.now(),
            //         type: 'system',
            //         content: explanation,
            //         timestamp: new Date()
            //     })
            // }

            let messagesToAdd: ChatMessageType[] = []

            if (data && text) {
                messagesToAdd.push({
                    id: 'analysis-' + Date.now(),
                    type: 'analysis',
                    content: formatAnalysisResults(text, data),
                    timestamp: new Date()
                })
                messagesToAdd.push({
                    id: 'analysis-confirm' + Date.now(),
                    type: 'analysis',
                    content:
                        <div className="space-y-4">
                            <h3 className="font-medium text-md mb-2 flex items-center gap-2">
                                <span>Does the data look correct or would you like to modify any value? </span>
                            </h3>
                            <div className="flex flex-wrap gap-4">
                                <Button variant="default" size="lg" className="flex-1 min-w-[200px]" onClick={() => handleUseData(data)}>
                                    Looks good, proceed to my results
                                </Button>
                                <Button variant="outline" size="lg" className="flex-1 min-w-[200px]" onClick={() => handleUseData(data, true)}>
                                    Manually modify my data
                                </Button>
                            </div>
                        </div>,
                    timestamp: new Date()
                })
            } else if (text) {
                messagesToAdd.push({
                    id: 'analysis-error' + Date.now(),
                    type: 'system',
                    content: text,
                    timestamp: new Date()
                })
            } else {
                messagesToAdd.push({
                    id: 'analysis-error' + Date.now(),
                    type: 'system',
                    content: 'An error occurred while processing the file, try again or contact us!',
                    timestamp: new Date()
                })
            }

            setMessages(prev => {
                let newMessages = [...prev, ...messagesToAdd]
                return newMessages
            })

        } catch (error) {
            // Add error message
            setMessages(prev => [...prev, {
                id: 'error-' + Date.now(),
                type: 'error',
                content: 'An error occurred while processing the file, please try again or contact us!',
                timestamp: new Date()
            }])
        } finally {
            setIsProcessing(false)
        }
    }

    const handleUseData = (data: SandAnalysisData, manualEdit: boolean = false) => {
        onDataUse?.(data, manualEdit)
        onOpenChange(false)
    }

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="max-w-4xl h-[80vh] flex flex-col p-1 md:p-8 rounded-2xl">
                {/* Header */}
                <div className="flex items-center justify-between py-4 border-b-2">
                    <h2 className="ml-4 text-xl font-semibold">CapillaryFlow AI Sand Agent</h2>
                </div>

                {/* Chat Messages */}
                <div className="flex-1 overflow-y-auto">
                    {messages.map((message) => (
                        <ChatMessage
                            key={message.id}
                            message={message}
                            onFileSelect={!isProcessing ? handleFileSelect : undefined}
                            onUseData={handleUseData}
                            isDeveloperMode={isDeveloperMode}
                        />
                    ))}
                    <div ref={messagesEndRef} />
                </div>
            </DialogContent>
        </Dialog>
    )
} 