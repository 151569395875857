import type { AdminDetailedDevice } from '@/data/admin/device'
import type { DataDownlink } from '@/data/admin/dataDownlink'
import { Button } from '@/components/ui/button'
import { Loader2 } from 'lucide-react'

interface DeviceControlsProps {
  device: AdminDetailedDevice
  downlinks: DataDownlink[]
  onBalanceValve: (shouldOpen: boolean) => Promise<void>
  isSubmitting?: boolean
}

export default function DeviceControls({ 
  device, 
  downlinks,
  onBalanceValve,
  isSubmitting = false
}: DeviceControlsProps) {
  if (!device.usesBalanceValve) {
    return null
  }

  // Find any pending balance valve commands
  const pendingBalanceValve = downlinks.find(
    d => d.balanceValveTime !== undefined && d.balanceValveTime !== null && d.balanceValveTime !== -1
  )
  
  const isPending = Boolean(pendingBalanceValve)

  return (
    <div className="rounded-lg border border-gray-200 p-4">
      <h3 className="mb-4 text-lg font-medium">Device Controls</h3>
      {device.usesBalanceValve  ? <div className="flex flex-wrap gap-4">
        <Button
          onClick={() => onBalanceValve(false)}
          variant="secondary"
          disabled={isPending || isSubmitting}
          className="min-w-[160px] relative"
        >
          {isSubmitting || isPending ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {isPending ? (
                `Pending Close`
              ) : (
                'Sending command...'
              )}
            </>
          ) : (
            `Close Balance Valve`
          )}
        </Button>
        <Button
          onClick={() => onBalanceValve(true)}
          variant="secondary"
          disabled={isPending || isSubmitting}
          className="min-w-[160px] relative"
        >
          {isSubmitting || isPending ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {isPending ? (
                `Pending Open`
              ) : (
                'Sending command...'
              )}
            </>
          ) : (
            `Open Balance Valve`
          )}
        </Button>
      </div> : null}
    </div>
  )
} 