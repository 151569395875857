import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { StringParam, useQueryParam } from 'use-query-params'
import { z } from 'zod'
import { CheckCircle, XCircle, AlertCircle } from 'lucide-react'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { useToast } from '@/components/ui/use-toast'
import useFirmwareVersion from '@/hooks/useFirmwareVersion'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'

import { Button } from '../ui/button'
import { Input } from '../ui/input'
import { Switch } from '../ui/switch'
import InfoTooltip from '../v2/InfoTooltip'
import useAdminDevices from '@/hooks/useAdminDevices'

const CreateUpdateFirmwareSchema = (isNew: boolean) =>
  z.object({
    isBetaVersion: z.boolean(),
    ...(isNew && {
      firmwareFile: z.custom<File | undefined>((file) => file instanceof File, {
        message: 'A firmware file is required',
      }),
    }),
  })

export type CreateUpdateFirmware = z.infer<
  ReturnType<typeof CreateUpdateFirmwareSchema>
>

function AddEditFirmwareModal() {
  const { toast } = useToast()
  const [id, setId] = useQueryParam('firmwareVersionId', StringParam)
  const { data, onCreate, onUpdate } = useFirmwareVersion(id)
  const { data: paginatedDevices } = useAdminDevices()

  const isOpen = Boolean(id)
  const isNew = id === 'new'

  const form = useForm<CreateUpdateFirmware>({
    resolver: zodResolver(CreateUpdateFirmwareSchema(isNew)),
    defaultValues: {
      firmwareFile: undefined,
      isBetaVersion: true,
    },
  })

  const isBetaVersion = useWatch({
    control: form.control,
    name: 'isBetaVersion',
    defaultValue: true // matches form default
  })

  useEffect(() => {
    if (data && !form.formState.isDirty) {
      form.reset({
        isBetaVersion: data.isBetaVersion,
      })
    }
  }, [data])

  const handleSubmit = async (data: CreateUpdateFirmware) => {
    try {
      if (isNew) {
        await onCreate(data)
      } else {
        await onUpdate({ id: Number(id!), data })
      }
      toast({
        title: `Firmware version ${isNew ? 'created' : 'updated'}!`,
        className: successfulToastClassNames,
      })
      handleClose()
    } catch (err) {
      toast({
        title: `Failed to ${isNew ? 'create' : 'update'} firmware version`,
        className: failureToastClassNames,
      })
    }
  }

  const handleClose = () => {
    form.reset()
    setId(null)
  }

  const { eligibleDevices, ineligibleDevices } = useMemo(() => {
    if (!paginatedDevices || !paginatedDevices.devices) return { eligibleDevices: [], ineligibleDevices: [] }

    const eligible: typeof paginatedDevices.devices = []
    const ineligible: typeof paginatedDevices.devices = []

    for (const device of paginatedDevices.devices) {
      if (!device.shouldUpdateViaFOTA || (isBetaVersion && !device.eligibleForBetaFirmwareVersions)) {
        ineligible.push(device)
      } else {
        eligible.push(device)

      }
    }

    return { eligibleDevices: eligible, ineligibleDevices: ineligible }
  }, [paginatedDevices, isBetaVersion])

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="max-h-[90%] max-w-5xl overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-3xl text-black">
            {isNew ? 'Create firmware version' : 'Update firmware version'}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            {isNew ? (
              <FormField
                control={form.control}
                name="firmwareFile"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>File</FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        name={field.name}
                        type="file"
                        accept=".bin"
                        onChange={(e) => {
                          field.onChange(e.target.files?.[0])
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ) : null}
            <div className="mt-8">
              <FormField
                control={form.control}
                name="isBetaVersion"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>
                      Beta version{' '}
                      <InfoTooltip
                        position="right"
                        text="Beta versions will only be visible for organizations with beta mode enabled"
                      />{' '}
                    </FormLabel>
                    <FormControl>
                      <Switch
                        className={'block'}
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="mt-8 space-y-6">
              <div>
                <div className="flex items-center gap-2 mb-4">
                  <CheckCircle className="text-green-500" />
                  <h3 className="text-lg font-medium">
                    Devices that will receive the update ({eligibleDevices.length})
                  </h3>
                </div>
                <div className="max-h-[265px] overflow-y-auto space-y-2">
                  {eligibleDevices.map(device => (
                    <div key={device.id} className="p-3 rounded-lg border flex items-center justify-between">
                      <span>{device.name}</span>
                      <div className="flex gap-3 text-sm">
                        <span className={`px-2 py-1 rounded-full ${device.shouldUpdateViaFOTA ? 'bg-green-100 text-green-700' : 'bg-red-200 text-red-700'}`}>
                          FOTA: {device.shouldUpdateViaFOTA ? 'enabled' : 'disabled'}
                        </span>
                        <span className={`px-2 py-1 rounded-full ${device.eligibleForBetaFirmwareVersions ? 'bg-green-100 text-green-700' : 'bg-red-200 text-red-700'}`}>
                          Beta: {device.eligibleForBetaFirmwareVersions ? 'eligible' : 'ineligible'}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {ineligibleDevices.length > 0 ? (
                <div>
                  <div className="flex items-center gap-2 mb-4">
                    <XCircle className="text-red-500" />
                    <h3 className="text-lg font-medium">
                      Devices that will not receive the update ({ineligibleDevices.length})
                    </h3>
                  </div>
                  <div className="rounded-lg border border-red-200 bg-red-50 p-4">
                    <div className="flex items-center gap-2 text-red-500 mb-2">
                      <AlertCircle size={20} />
                      <span className="font-medium">FOTA Updates Disabled</span>
                    </div>
                    <p className="text-red-500 mb-4">
                      The following devices have FOTA updates disabled and/or are not eligible for beta firmware versions
                    </p>
                    <div className="max-h-[265px] overflow-y-auto space-y-2">
                      {ineligibleDevices.map(device => (
                        <div key={device.id} className="p-3 rounded-lg bg-red-100 flex items-center justify-between">
                          <span>{device.name}</span>
                          <div className="flex gap-3 text-sm">
                            <span className={`px-2 py-1 rounded-full ${device.shouldUpdateViaFOTA ? 'bg-green-100 text-green-700' : 'bg-red-200 text-red-700'}`}>
                              FOTA: {device.shouldUpdateViaFOTA ? 'enabled' : 'disabled'}
                            </span>
                            <span className={`px-2 py-1 rounded-full ${device.eligibleForBetaFirmwareVersions ? 'bg-green-100 text-green-700' : 'bg-red-200 text-red-700'}`}>
                              Beta: {device.eligibleForBetaFirmwareVersions ? 'enabled' : 'disabled'}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="mt-8 flex items-center justify-end gap-2">
              <Button variant="ghost" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="default"
                type="submit"
                isLoading={form.formState.isSubmitting}
              >
                Submit
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
export default AddEditFirmwareModal
