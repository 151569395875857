import { useParams } from 'react-router-dom'

import { useToast } from '@/components/ui/use-toast'
import DeviceLoadingPage from '@/components/v2/skeletons/DeviceLoadingPage'
import DeviceInfo from '@/components/admin/device-page/DeviceInfo'
import DeviceHeader from '@/components/admin/device-page/DeviceHeader'
import DeviceControls from '@/components/admin/device-page/DeviceControls'
import PendingCommands from '@/components/admin/device-page/PendingCommands'
import useAdminDevice from '@/hooks/useAdminDevice'
import useAdminDataDownlinks from '@/hooks/useAdminDataDownlinks'
import useAdminDataDownlink from '@/hooks/useAdminDataDownlink'
import { successfulToastClassNames, failureToastClassNames } from '@/utils/toastHelpers'
import { useState } from 'react'

function AdminDevicePage() {
  const { deviceId } = useParams()
  const { toast } = useToast()
  const [isSubmittingRestart, setIsSubmittingRestart] = useState(false)
  const [isSubmittingValve, setIsSubmittingValve] = useState(false)

  const { data: device, isLoading } = useAdminDevice(deviceId || '', 2000)
  const { onCreate, onDelete } = useAdminDataDownlink(null)

  // Fetch pending downlinks for this device
  const { data: downlinksData, isLoading: isLoadingDownlinks } = useAdminDataDownlinks(
    device?.imei,
    undefined,
    10,
    0,
    undefined,
    undefined,
    false,
    2000
  )

  const handleRestart = async () => {
    if (!device) return

    setIsSubmittingRestart(true)
    try {
      await onCreate({
        imei: device.imei,
        shouldReboot: 1,
        // Set other required fields to -1 (do nothing)
        fillValveTime: -1,
        drainValveTime: -1,
        dripLineValveTime: -1,
        balanceValveTime: -1,
        setAutoRegulate: -1,
        setActiveSection: -1,
        shouldFetchConfig: -1,
      })

      toast({
        title: `Device will restart next time it connects to the network`,
        description: 'This might up to a minute depending on how often the device sends data',
        className: successfulToastClassNames,
      })
    } catch (err) {
      toast({
        title: `Failed restart device`,
        description: 'Try again in a few minutes. Contact support if the problem keeps occurring',
        className: failureToastClassNames,
      })
    } finally {
      setIsSubmittingRestart(false)
    }
  }

  const handleBalanceValve = async (shouldOpen: boolean) => {
    if (!device) return

    setIsSubmittingValve(true)
    try {
      await onCreate({
        imei: device.imei,
        balanceValveTime: shouldOpen ? 60 : 0,
        // Set other required fields to -1 (do nothing)
        fillValveTime: -1,
        drainValveTime: -1,
        dripLineValveTime: -1,
        setAutoRegulate: -1,
        setActiveSection: -1,
        shouldFetchConfig: -1,
        shouldReboot: -1,
      })

      toast({
        title: `Balance valve will ${shouldOpen ? 'open' : 'close'} next time device connects`,
        description: 'This might take up to a minute depending on how often the device sends data',
        className: successfulToastClassNames,
      })
    } catch (err) {
      toast({
        title: `Failed to control balance valve`,
        description: 'Try again in a few minutes. Contact support if the problem keeps occurring',
        className: failureToastClassNames,
      })
    } finally {
      setIsSubmittingValve(false)
    }
  }

  const handleDeleteDownlink = async (id: number) => {
    try {
      await onDelete(id)
      toast({
        title: 'Command cancelled',
        className: successfulToastClassNames,
      })
    } catch (err) {
      toast({
        title: 'Failed to cancel command',
        description: 'Try again in a few minutes. Contact support if the problem keeps occurring',
        className: failureToastClassNames,
      })
    }
  }

  return (
    <div className="mx-auto w-full max-w-4xl px-2 py-4 sm:p-4">
      <div className="flex flex-col gap-4">
        <DeviceHeader 
          onRestart={handleRestart} 
          downlinks={downlinksData?.downlinks || []}
          isSubmitting={isSubmittingRestart}
        />

        {isLoading ? (
          <DeviceLoadingPage />
        ) : device ? (
          <>
            <DeviceInfo device={device} />
            <DeviceControls
              device={device}
              downlinks={downlinksData?.downlinks || []}
              onBalanceValve={handleBalanceValve}
              isSubmitting={isSubmittingValve}
            />
            <PendingCommands
              downlinks={downlinksData?.downlinks || []}
              isLoading={isLoadingDownlinks}
              onDelete={handleDeleteDownlink}
            />
          </>
        ) : (
          <div className="text-center">Device not found</div>
        )}
      </div>
    </div>
  )
}

export default AdminDevicePage 
