import { useNavigate, useParams } from 'react-router-dom'
import { useSandStandards } from '@/hooks/useSandStandards'
import { Loader2 } from 'lucide-react'
import { useState, useEffect, useMemo } from 'react'
import { SandVisualization } from '@/components/public/sand-analysis/SandVisualization'
import { SandMeasurementInput } from '@/components/public/sand-analysis/SandMeasurementInput'
import useSandAnalysis from '@/hooks/useSandAnalysis'
import SandContactFormModal from '@/components/public/sand-analysis/SandContactFormModal'
import { MultiSandStandardSelector } from '@/components/public/sand-analysis/MultiSandStandardSelector'
import { useForm } from 'react-hook-form'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { useToast } from '@/components/ui/use-toast'
import { useQueryParam, StringParam } from 'use-query-params'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"
import { Upload } from 'lucide-react'
import { PrintAnalysisView } from '@/components/public/sand-analysis/PrintAnalysisView'

interface FormData {
    id?: string;
    isNew: boolean;
    sampleName: string;
    measurements: {
        meshNumber: string;
        diameterMm: number;
        retainedGrams: number;
    }[];
}

export default function WizardStandardPage() {
    const { toast } = useToast()
    const { sessionId } = useParams()
    const navigate = useNavigate()
    const { data: standards } = useSandStandards()
    const {
        session,
        isLoading: isLoadingSession,
        refetchSession,
        createAnalysis,
        isCreatingAnalysis
    } = useSandAnalysis(sessionId)
    const [selectedStandardIds, setSelectedStandards] = useState<string[]>([])
    const [activeTabId, setActiveTabId] = useQueryParam('tabId', StringParam)
    const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false)
    const [showAddModal, setShowAddModal] = useState(false)
    const [pendingTabId, setPendingTabId] = useState<string | null>(null)
    const [hiddenItems, setHiddenItems] = useState<Record<string, boolean>>({})

    const form = useForm<FormData>({
        defaultValues: {
            isNew: false,
            sampleName: '',
            measurements: []
        }
    })

    // Initialize active tab and form data when session loads
    useEffect(() => {
        // if we dont have analyses, do nothing
        if (!session?.analyses.length) {
            return
        }

        // if we have an active tab, use that
        const selectedAnalysis = session?.analyses?.find(a => a.id === activeTabId)
        if (selectedAnalysis) {
            form.reset({
                id: selectedAnalysis.id,
                isNew: false,
                sampleName: selectedAnalysis.sampleName || '',
                measurements: selectedAnalysis.measurements || []
            })
        } else { //default to the first analysis
            setActiveTabId(session.analyses[0].id)
            const firstAnalysis = session.analyses[0]
            form.reset({
                id: firstAnalysis.id,
                isNew: false,
                sampleName: firstAnalysis.sampleName || '',
                measurements: firstAnalysis.measurements || []
            })
        }

    }, [session, activeTabId, setActiveTabId])

    // Navigation protection
    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (form.formState.isDirty) {
                e.preventDefault()
                e.returnValue = ''
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => window.removeEventListener('beforeunload', handleBeforeUnload)
    }, [form.formState.isDirty])

    const handleContactSuccess = async () => {
        await refetchSession()
    }

    const handleSave = async () => {
        const formData = form.getValues()

        try {
            const saveData = {
                ...(formData.isNew ? {} : { id: formData.id }),
                sampleName: formData.sampleName,
                measurements: formData.measurements
            }

            const result = await createAnalysis(saveData)
            await refetchSession()
            form.reset(form.getValues())
            setActiveTabId(result.id)

            toast({
                title: "Changes saved",
                description: formData.isNew
                    ? "New sample analysis has been created."
                    : "Sample analysis has been updated.",
            })
        } catch (error) {
            console.error('Failed to save analysis:', error)
            toast({
                variant: "destructive",
                title: "Failed to save changes",
                description: "There was a problem saving your changes. Please try again.",
            })
        }
    }

    const handleTabChange = async (newTabId: string) => {
        if (form.formState.isDirty) {
            setPendingTabId(newTabId)
            setShowUnsavedChangesDialog(true)
            return
        }

        switchTab(newTabId)
    }

    const switchTab = (newTabId: string) => {
        const analysis = session?.analyses.find(a => a.id === newTabId)

        if (analysis) {
            form.reset({
                id: analysis.id,
                isNew: false,
                sampleName: analysis.sampleName || '',
                measurements: analysis.measurements || []
            })
        } else if (newTabId.startsWith('new-')) {
            // Create 5 empty rows by default for new tabs
            const defaultMeasurements = Array(5).fill({
                meshNumber: '',
                diameterMm: 0,
                retainedGrams: 0,
            })

            form.reset({
                isNew: true,
                sampleName: '',
                measurements: defaultMeasurements
            })
        }
        setActiveTabId(newTabId || null)
    }

    const handleUnsavedChangesConfirm = () => {
        if (pendingTabId) {
            switchTab(pendingTabId)
            setPendingTabId(null)
        }
        setShowUnsavedChangesDialog(false)
    }

    const handleUnsavedChangesCancel = () => {
        setPendingTabId(null)
        setShowUnsavedChangesDialog(false)
    }

    const handleAddNewSample = () => {
        setShowAddModal(true)
    }

    const handleAIUpload = () => {
        navigate(`/sand-analysis/analyze/${sessionId}/data?openUpload=true`)
    }

    const handleManualEntry = () => {
        navigate(`/sand-analysis/analyze/${sessionId}/data?showManual=true`)
    }

    // Get the currently selected standard for visualization
    const selectedStandards = useMemo(() => standards?.filter(s => selectedStandardIds.includes(s.id)) || [], [standards, selectedStandardIds])

    // Transform session analyses for visualization
    const samples = useMemo(() => session?.analyses.map(analysis => ({
        id: analysis.id,
        name: analysis.sampleName,
        measurements: analysis.measurements,
    })) ?? [], [session])

    const handlePrint = () => {
        window.print();
    };

    if (isLoadingSession) {
        return (
            <div className="flex items-center justify-center min-h-[50vh]">
                <div className="text-center">
                    <Loader2 className="h-8 w-8 animate-spin mx-auto mb-4" />
                    <p className="text-sm text-muted-foreground">Loading...</p>
                </div>
            </div>
        )
    }

    const canCloseContactModal = !!(session?.contactName && session?.contactEmail)

    return (
        <div>
            {/* Print View */}
            <PrintAnalysisView
                hiddenItems={hiddenItems}
                setHiddenItems={setHiddenItems}
                analyses={session?.analyses || []}
                standards={standards || []}
                selectedStandardIds={selectedStandardIds}
            /> 
            <SandContactFormModal
                open={!canCloseContactModal}
                onOpenChange={() => { }}
                onSuccess={handleContactSuccess}
                canClose={canCloseContactModal}
            />

            <AlertDialog open={showUnsavedChangesDialog} onOpenChange={setShowUnsavedChangesDialog}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Unsaved Changes</AlertDialogTitle>
                        <AlertDialogDescription>
                            You have unsaved changes. Do you want to discard them and switch tabs?
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel onClick={handleUnsavedChangesCancel}>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={handleUnsavedChangesConfirm}>Continue</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>

            <Dialog open={showAddModal} onOpenChange={setShowAddModal}>
                <DialogContent className="sm:max-w-[800px]">
                    <DialogHeader>
                        <DialogTitle>Add additional sample</DialogTitle>
                        <DialogDescription>
                            You can either upload the report(s) you have received from the lab and have them automatically
                            analysed or enter your data manually.
                        </DialogDescription>
                        <p className="text-sm text-muted-foreground">
                            Supported file formats are PDF or Image files up to 10MB
                        </p>
                    </DialogHeader>

                    <DialogFooter className="flex gap-4 sm:gap-0">
                        <Button
                            size="lg"
                            className="flex-1 bg-[#0072CE] hover:bg-[#0072CE]/90 rounded-xl h-auto py-3"
                            onClick={handleAIUpload}
                        >
                            Upload file and analyze with AI
                            <Upload className="ml-2 h-5 w-5" />
                        </Button>
                        <Button
                            variant="outline"
                            size="lg"
                            className="flex-1 rounded-xl h-auto py-3"
                            onClick={handleManualEntry}
                        >
                            I want to enter my numbers manually
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

            <div className="container px-2 lg:px-8 max-w-6xl mx-auto py-16 space-y-8 print:hidden">
                <div className="space-y-4">
                    <div className="flex items-center justify-between">
                        <div>
                            <div className="inline-flex items-center justify-center rounded-full bg-sand px-2.5 py-0.5 text-sm text-slate-600">
                                2/2
                            </div>
                            <h1 className="text-2xl font-semibold">Analysis results</h1>
                            <p className="text-muted-foreground">
                                Compare your sample with industry standards
                            </p>
                        </div>
                    </div>

                    <MultiSandStandardSelector
                        value={selectedStandardIds}
                        onChange={setSelectedStandards}
                    />
                </div>

                <div className="flex flex-wrap gap-8">
                    <div className="flex-1 min-w-[350px] md:max-w-[450px]">
                        <Form {...form}>
                            <div className="space-y-4">
                                <SandMeasurementInput
                                    control={form.control}
                                    activeTabId={activeTabId || ''}
                                    analyses={session?.analyses || []}
                                    onTabChange={handleTabChange}
                                    onAddNewSample={handleAddNewSample}
                                />

                                <div className="flex justify-end">
                                    <Button
                                        onClick={handleSave}
                                        disabled={!form.formState.isDirty || isCreatingAnalysis}
                                    >
                                        {isCreatingAnalysis ? (
                                            <>
                                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                                Saving...
                                            </>
                                        ) : (
                                            'Save changes'
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="flex flex-col gap-4 overflow-scroll">
                        <div className="flex-1 min-w-[400px] border rounded-lg p-4 bg-card self-start">
                            <SandVisualization
                                hiddenItems={hiddenItems}
                                setHiddenItems={setHiddenItems}
                                samples={samples}
                                referenceStandards={selectedStandards}
                                hasUserInput={false}
                                isNew={false}
                            />
                        </div>
                        <Button
                            onClick={handlePrint}
                            className="ml-auto mt-4"
                        >
                            Export Results
                            <span className="ml-2">📄</span>
                        </Button>

                    </div>
                </div>
            </div>
        </div>
    )
} 