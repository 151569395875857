import { Link, useNavigate } from 'react-router-dom'
import { Button } from '@/components/ui/button'

// Image imports
import VectorHorse from '/vector-horse.png?url'
import VectorSand from '/vector-sand.png?url'
import SandExampleGraph from '/sand-example-graph.png?url'
import { createSession } from '@/data/sand-analysis'
import { useState } from 'react'
import { Loader2 } from 'lucide-react'
const SandAnalysisLandingPage = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    const handleCreateSession = async () => {
        // Create new session
        setIsLoading(true)
        const session = await createSession()
        setIsLoading(false)
        navigate(`/sand-analysis/analyze/${session.id}/data`)
    }
    return (
        <div className="md:max-h-[90vh] overflow-hidden">
            <div className="flex flex-col items-center justify-center py-8 md:min-h-[95vh] text-center overflow-hidden">
                {/* Header Content */}
                <div className="space-y-6 max-w-3xl">
                    <h1 className="text-6xl font-bold tracking-tight text-[#002B49] leading-[78px]">
                        CapillaryFlow Sand Agent
                    </h1>
                    <p className="text-xl text-muted-foreground">
                        Analyze your sand sample and benchmark against industry standards
                    </p>

                    {/* Action Buttons */}
                    <div className="flex items-center justify-center gap-4 pt-4">
                        <Button size="lg" className="bg-[#0072CE] hover:bg-[#0072CE]/90" onClick={handleCreateSession} disabled={isLoading}>
                            {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : "Get started"}
                        </Button>
                        {/* <Link to="/sand-analysis/resources/guide">
                            <Button size="lg" variant="outline">
                                Guides and resources
                            </Button>
                        </Link> */}
                    </div>
                </div>

                {/* Images Grid */}
                <div className="mt-16 w-screen relative flex justify-center md:justify-between items-center">
                    <div className="hidden md:block w-[81vw] relative">
                        <img src={VectorHorse} alt="Horse in sand" className="w-full h-auto object-contain" />
                    </div>
                    <div className="w-[85vw] md:w-[40vw] md:absolute md:left-1/2 md:-translate-x-1/2 md:-top-8">
                        <img src={SandExampleGraph} alt="Sand analysis graph" className="w-full h-auto object-contain" />
                    </div>
                    <div className="hidden md:block w-[29vw] relative">
                        <img src={VectorSand} alt="Golf course" className="w-full h-auto object-contain" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SandAnalysisLandingPage 