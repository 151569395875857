import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Trash2 } from 'lucide-react'
import type { DataDownlink } from '@/data/admin/dataDownlink'
import { dateWithTimeInIsoFormat } from '@/utils/dateHelpers'

interface PendingCommandsProps {
  downlinks: DataDownlink[]
  isLoading: boolean
  onDelete?: (id: number) => Promise<void>
}

export default function PendingCommands({ downlinks, isLoading, onDelete }: PendingCommandsProps) {
  return (
    <div className="rounded-lg border border-gray-200 p-4">
      <h3 className="mb-4 text-lg font-medium">Pending Commands</h3>
      {isLoading ? (
        <p>Loading pending commands...</p>
      ) : downlinks && downlinks.length > 0 ? (
        <div className="space-y-4">
          {downlinks.map((downlink) => (
            <div key={downlink.id} className="flex items-center justify-between rounded-lg bg-gray-50 p-3">
              <div className="flex flex-col">
                <span className="text-sm font-medium">
                  {Number(downlink.shouldReboot) === 1 && 'Device Restart'}
                  {downlink.balanceValveTime !== undefined && downlink.balanceValveTime !== null && downlink.balanceValveTime !== -1 && (
                    `Balance Valve ${downlink.balanceValveTime > 0 ? 'Open' : 'Close'}`
                  )}
                </span>
                <span className="text-xs text-gray-500">
                  Created at: {dateWithTimeInIsoFormat(new Date(downlink.createdAt))}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <Badge>Pending</Badge>
                {onDelete && (
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-8 w-8 text-red-600 hover:bg-red-100 hover:text-red-700"
                    onClick={() => onDelete(downlink.id)}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500">No pending commands</p>
      )}
    </div>
  )
} 