import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Loader2, Upload, FileText } from 'lucide-react'
import { FileUploadZoneProps } from './types'

const DEFAULT_MAX_SIZE = 10 * 1024 * 1024 // 10MB

export function FileUploadZone({ onFileSelect, accept, maxSize = DEFAULT_MAX_SIZE, loading }: FileUploadZoneProps) {
    const [dragActive, setDragActive] = useState(false)
    const [selectedFile, setSelectedFile] = useState<File | null>(null)

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles?.[0]) {
            setSelectedFile(acceptedFiles[0])
        }
    }, [])

    const handleConfirm = () => {
        if (selectedFile) {
            onFileSelect(selectedFile)
        }
    }

    const handleReset = () => {
        setSelectedFile(null)
    }

    const { getRootProps, getInputProps, isDragReject } = useDropzone({
        onDrop,
        accept: accept ? { 'application/pdf': ['.pdf'], 'image/*': ['.png', '.jpg', '.jpeg'] } : undefined,
        maxSize,
        multiple: false,
        onDragEnter: () => setDragActive(true),
        onDragLeave: () => setDragActive(false),
        onDropAccepted: () => setDragActive(false),
        onDropRejected: () => setDragActive(false),
    })

    if (selectedFile) {
        return (
            <div className="space-y-4">
                <div className="rounded-lg border border-border p-4 bg-white">
                    <div className="flex items-center gap-2">
                        <FileText className="ml-4 h-4 w-4 text-muted-foreground" />
                        <p className="text-sm font-medium">{selectedFile.name}</p>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row gap-4">
                    <Button onClick={handleConfirm} disabled={loading}>
                        Does this look correct?
                    </Button>
                    <Button variant="outline" onClick={handleReset} disabled={loading}>
                        No, upload another file
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div
            {...getRootProps()}
            className={cn(
                'relative rounded-lg border-2 border-dashed transition-all duration-200',
                'bg-white hover:bg-background/80',
                dragActive ? 'border-primary scale-[1.02] bg-background/80' : 'border-muted-foreground/25',
                isDragReject && 'border-destructive/50 bg-destructive/10',
                'cursor-pointer group'
            )}
        >
            <input {...getInputProps()} />
            
            <div className="flex min-h-[150px] flex-col items-center justify-center gap-4 p-8 text-center">
                <div className={cn(
                    'rounded-full p-4 transition-all duration-200',
                    'bg-white group-hover:bg-muted',
                    'rounded-[30px]',
                    dragActive && 'bg-primary/10 scale-110',
                    isDragReject && 'bg-destructive/10'
                )}>
                    {loading ? (
                        <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
                    ) : (
                        <Upload className={cn(
                            'h-8 w-8 transition-colors duration-200',
                            dragActive ? 'text-primary' : 'text-muted-foreground',
                            isDragReject && 'text-destructive'
                        )} />
                    )}
                </div>

                <div className="space-y-2">
                    <p className={cn(
                        'text-lg font-medium transition-colors duration-200',
                        dragActive ? 'text-primary' : 'text-foreground',
                        isDragReject && 'text-destructive'
                    )}>
                        Please upload or take a photo of your sand analysis report
                    </p>
                    {isDragReject ? (
                        <p className="text-sm text-destructive">This file type is not supported</p>
                    ) : dragActive ? (
                        <p className="text-sm text-primary">Drop your file here</p>
                    ) : (
                        <Button
                            type="button"
                            variant="secondary"
                            size="sm"
                            className="pointer-events-none"
                            disabled={loading}
                        >
                            Select File
                        </Button>
                    )}
                    <p className="text-xs text-muted-foreground/75">
                        PDF or Image files up to {Math.round(maxSize / 1024 / 1024)}MB
                    </p>
                </div>
            </div>
        </div>
    )
} 