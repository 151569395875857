import { SandVisualization } from './SandVisualization'
import type { ReferenceStandard, SandAnalysisResult } from '@/data/sand-analysis'
import CapillaryFlow from '@/assets/capillaryflow.svg?react'

interface PrintAnalysisViewProps {
    analyses: SandAnalysisResult[]
    standards: ReferenceStandard[]
    selectedStandardIds: string[]
    hiddenItems: Record<string, boolean>
    setHiddenItems: (items: Record<string, boolean>) => void
}

export function PrintAnalysisView({
    analyses,
    standards,
    selectedStandardIds,
    hiddenItems,
    setHiddenItems,
}: PrintAnalysisViewProps) {
    const selectedStandards = standards.filter(s => selectedStandardIds.includes(s.id))

    return (
        <div
            id="print-template"
            className="h-[0px] collapse print:visible print:static print:left-0 m-0 print:h-full overflow-hidden"
        >
            <CapillaryFlow width={128} />
            {/* Title Section */}
            <div className="print:w-full print:mb-12 print:text-center print:mt-16">
                <h1 className="print:text-[48px] print:font-bold print:text-[#0B2545] print:mb-4">
                    CapillaryFlow Sand Agent
                </h1>
                <p className="print:text-xl print:text-gray-600">
                    Analyze your sand sample and benchmark against industry standards
                </p>
            </div>

            {/* Visualization Section */}
            <div className="print:mb-8 w-[488px] h-[600px]">
                <SandVisualization
                    samples={analyses.map(analysis => ({
                        id: analysis.id,
                        name: analysis.sampleName,
                        measurements: analysis.measurements
                    }))}
                    referenceStandards={selectedStandards}
                    hasUserInput={false}
                    isNew={false}
                    hiddenItems={hiddenItems}
                    setHiddenItems={setHiddenItems}
                />
            </div>

            {/* Measurements Section */}
            <div className="print:w-full print:grid print:grid-cols-2 print:gap-8">
                {analyses.map((analysis) => (
                    <div key={analysis.id} className="print:break-inside-avoid">
                        <h3 className="print:text-xl print:font-semibold print:mb-4">{analysis.sampleName}</h3>
                        <table className="print:w-full">
                            <thead>
                                <tr className="print:border-b print:border-gray-200">
                                    <th className="print:text-left print:p-2">#</th>
                                    <th className="print:text-left print:p-2">Ø (mm)</th>
                                    <th className="print:text-left print:p-2">Retained (g)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {analysis.measurements.map((m) => (
                                    <tr key={m.meshNumber} className="print:border-b print:border-gray-100">
                                        <td className="print:p-2">{m.meshNumber}</td>
                                        <td className="print:p-2">{m.diameterMm}</td>
                                        <td className="print:p-2">{m.retainedGrams}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
        </div>
    )
}