import { cn } from '@/lib/utils'
import { ChatMessageProps } from './types'
import { FileUploadZone } from './FileUploadZone'
import { Loader2 } from 'lucide-react'
import AIAvatar from '/ai-avatar.png?url'
import { Progress } from './Progress'

function ProcessingProgress({ progress }: { progress: NonNullable<ChatMessageProps['message']['metadata']>['progress'] }) {
    if (!progress) return null

    const { stage, currentPage, totalPages, percentage } = progress
    const stageText = stage === 'calculating' ? 'Analyzing' : 'Converting'

    return (
        <div className="mt-2 space-y-2">
            <div className="flex items-center justify-between text-xs text-muted-foreground">
                <span>
                    {stageText} page {currentPage} of {totalPages}
                </span>
                <span>{Math.round(percentage)}%</span>
            </div>
            <Progress value={percentage} />
        </div>
    )
}

export function ChatMessage({ message, onFileSelect, isDeveloperMode }: ChatMessageProps) {
    const isSystem = message.type === 'system'
    const isError = message.type === 'error'
    const isSuccess = message.type === 'success'
    const isUpload = message.type === 'upload'
    const isAnalysis = message.type === 'analysis'
    const isLoading = message.metadata?.loading
    const isUser = message.type === 'user'
    const progress = message.metadata?.progress
    const isDisclaimer = message.id === 'disclaimer'

    return (
        <div
            className={cn(
                'flex w-full gap-4 px-1 md:px-6 py-4 transition-all duration-200 ease-out',
                isError && 'border-l-2 border-destructive',
                isSuccess && 'border-l-2 border-success',
                'animate-in fade-in-0 slide-in-from-bottom-4'
            )}
        >
            {/* Message Icon/Avatar */}
            <div className={cn(
                'flex h-8 w-8 shrink-0 select-none items-center justify-center rounded-md transition-colors overflow-hidden',
                isUser ? 'bg-primary' : 'bg-blue-100/50',
                isError && 'bg-destructive/10',
                isSuccess && 'bg-success/10'
            )}>
                {isUser ? (
                    <span className="text-sm font-semibold text-primary-foreground">U</span>
                ) : (
                    <div className="relative w-full h-full">
                        <img
                            src={AIAvatar}
                            alt="AI Assistant"
                            className="w-full h-full object-cover"
                        />
                    </div>
                )}
            </div>

            {/* Message Content */}
            <div className="flex-1 space-y-4 max-w-3xl bg-blue-50/60 py-4 px-6 rounded-xl">
                <div className={cn(
                    'prose prose-sm max-w-none space-y-2',
                    isError && 'text-destructive',
                    isDisclaimer && 'text-muted-foreground [&_strong]:text-blue-500',
                    'leading-relaxed'
                )}>
                    {typeof message.content === 'string' ? (
                        message.content.split('\n\n').map((paragraph, i) => (
                            <p key={i} className="m-0">
                                {isLoading && i === 0 ? (
                                    <span className="inline-flex items-center gap-2">
                                        {progress ? (
                                            <>Processing your file</>
                                        ) : (
                                            <>I'm analyzing your file</>
                                        )}
                                        <Loader2 className="h-4 w-4 animate-spin text-blue-500" />
                                    </span>
                                ) : (
                                    paragraph
                                )}
                            </p>
                        ))
                    ) : (
                        message.content
                    )}
                    {isLoading && progress && <ProcessingProgress progress={progress} />}
                </div>

                {/* File Upload Zone */}
                {isUpload && onFileSelect && (
                    <div className="mt-4 transition-transform duration-200 ease-out hover:scale-[1.01]">
                        <FileUploadZone
                            onFileSelect={onFileSelect}
                            accept="application/pdf,image/*"
                            maxSize={10 * 1024 * 1024} // 10MB
                            loading={isLoading}
                        />
                    </div>
                )}

                {/* Developer Mode Details */}
                {isDeveloperMode && message.metadata && (
                    <pre className="mt-4 rounded bg-muted/50 p-4 text-[10px] font-mono">
                        {JSON.stringify(message.metadata, null, 2)}
                    </pre>
                )}
            </div>
        </div>
    )
} 