import { Link, Outlet, useLocation } from 'react-router-dom'
import CapillaryFlow from '@/assets/capillaryflow.svg?react'
import { Button } from '@/components/ui/button'
import { Toaster } from '@/components/ui/toaster'
import { cn } from '@/lib/utils'

const SandAnalysisLayout = () => {
    const location = useLocation()
    const isAnalysisActive = location.pathname === '/sand-analysis'
    const isPreviousActive = location.pathname.includes('/sand-analysis/previous')

    return (
        <div className="min-h-screen bg-background">
            {/* Header */}
            <header className="bg-sand m-2 rounded-lg print:hidden">
                {/* Mobile Layout */}
                <div className="sm:hidden flex flex-col">
                    <div className="py-2 flex justify-center">
                        <Link to="/sand-analysis" className="bg-white px-3 rounded-lg">
                            <CapillaryFlow width={128} />
                        </Link>
                    </div>
                    <nav className="grid grid-cols-2 w-full">
                        <Link
                            to="/sand-analysis"
                            className={cn(
                                "flex items-center justify-center px-4 py-3 text-sm font-medium transition-colors",
                                "hover:bg-white/10",
                                isAnalysisActive && "bg-white/20"
                            )}
                        >
                            Analysis
                        </Link>
                        <Link
                            to="/sand-analysis/previous"
                            className={cn(
                                "flex items-center justify-center px-4 py-3 text-sm font-medium transition-colors",
                                "hover:bg-white/10",
                                isPreviousActive && "bg-white/20"
                            )}
                        >
                            My previous results
                        </Link>
                    </nav>
                </div>

                {/* Desktop Layout */}
                <div className="hidden sm:flex container h-14 items-center justify-between">
                    <div className="flex items-center gap-1">
                        <Link to="/sand-analysis" className="bg-white px-3 rounded-lg">
                            <CapillaryFlow width={128} />
                        </Link>
                        <nav className="flex gap-1 ml-4">
                            <Button
                                asChild
                                variant="ghost"
                                className={cn(isAnalysisActive && "bg-white/20")}
                            >
                                <Link to="/sand-analysis">Analysis</Link>
                            </Button>
                            <Button
                                asChild
                                variant="ghost"
                                className={cn(isPreviousActive && "bg-white/20")}
                            >
                                <Link to="/sand-analysis/previous">My previous results</Link>
                            </Button>
                        </nav>
                    </div>
                </div>
            </header>

            {/* Main content */}
            <main>
                <Outlet />
            </main>

            <Toaster />
        </div>
    )
}

export default SandAnalysisLayout 