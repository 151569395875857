import { Button } from '@/components/ui/button'
import { Loader2 } from 'lucide-react'
import type { DataDownlink } from '@/data/admin/dataDownlink'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'

interface DeviceHeaderProps {
  onRestart: () => Promise<void>
  downlinks: DataDownlink[]
  isSubmitting?: boolean
}

export default function DeviceHeader({ onRestart, downlinks, isSubmitting = false }: DeviceHeaderProps) {
  // Check for pending restart command
  const hasPendingRestart = downlinks.some(d => Number(d.shouldReboot) === 1)
  return (
    <div className="flex items-center justify-between">
      <h2 className="text-3xl">Device Administration</h2>
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button 
            variant="outline"
            disabled={hasPendingRestart || isSubmitting}
            className="min-w-[140px]"
          >
            {isSubmitting || hasPendingRestart ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                {hasPendingRestart ? 'Pending restart' : 'Sending command...'}
              </>
            ) : (
              'Restart Device'
            )}
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Are you sure you want to restart this device?
            </AlertDialogTitle>
            <AlertDialogDescription>
              The device will restart next time it connects to the network
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={onRestart}>
              Restart
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
} 