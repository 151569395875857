import type { AdminDetailedDevice } from '@/data/admin/device'

interface DeviceInfoProps {
  device: AdminDetailedDevice
}

export default function DeviceInfo({ device }: DeviceInfoProps) {
  return (
    <div className="rounded-lg border border-gray-200 p-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h3 className="text-sm font-medium text-gray-500">Device Name</h3>
          <p className="mt-1">{device.name}</p>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-500">IMEI</h3>
          <p className="mt-1">{device.imei}</p>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-500">Type</h3>
          <p className="mt-1">{device.type}</p>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-500">Status</h3>
          <p className="mt-1">{device.status}</p>
        </div>
      </div>
    </div>
  )
} 