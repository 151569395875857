import { Check, ChevronDown, X } from 'lucide-react'
import { useSandStandards } from '@/hooks/useSandStandards'
import { useState, useMemo } from 'react'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Command, CommandGroup, CommandItem } from '@/components/ui/command'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { Loader2 } from 'lucide-react'

interface MultiSandStandardSelectorProps {
    value: string[]
    onChange: (standardIds: string[]) => void
}

export function MultiSandStandardSelector({
    value: selectedStandardIds,
    onChange,
}: MultiSandStandardSelectorProps) {
    const { data: standards, isLoading } = useSandStandards()
    const [open, setOpen] = useState(false)

    const options = useMemo(() =>
        standards?.map(standard => ({
            label: standard.name,
            value: standard.id,
            description: "Description of this option and how it compares"
        })) || [],
        [standards]
    )

    const handleUnselect = (standardId: string) => {
        onChange(selectedStandardIds.filter(id => id !== standardId))
    }

    if (isLoading) {
        return (
            <div className="flex items-center gap-2 text-muted-foreground">
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Loading standards...</span>
            </div>
        )
    }

    return (
        <div className="w-[300px] print:hidden">
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={open}
                        className={`w-full justify-between px-3 ${
                            selectedStandardIds.length > 1 ? 'h-full min-h-[40px]' : 'h-10'
                        }`}
                        onClick={() => setOpen(!open)}
                    >
                        <div className="flex flex-wrap gap-1">
                            {selectedStandardIds.length === 0 && (
                                <span className="text-muted-foreground">Select standards</span>
                            )}
                            {selectedStandardIds.map((standardId) => {
                                const standard = options.find(opt => opt.value === standardId)
                                if (!standard) return null
                                return (
                                    <Badge
                                        variant="secondary"
                                        key={standardId}
                                        className="mb-1 mr-1 bg-sand"
                                    >
                                        {standard.label}
                                        <button
                                            className="ring-offset-background focus:ring-ring ml-1 rounded-full outline-none focus:ring-2 focus:ring-offset-2"
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleUnselect(standardId)
                                                }
                                            }}
                                            onMouseDown={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                            }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                handleUnselect(standardId)
                                            }}
                                        >
                                            <X className="text-muted-foreground hover:text-foreground h-3 w-3" />
                                        </button>
                                    </Badge>
                                )
                            })}
                        </div>
                        <ChevronDown className="h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[var(--radix-popper-anchor-width)] p-0 print:hidden" align="start">
                    <Command>
                        <CommandGroup className="max-h-64 overflow-auto md:max-h-64 max-h-[50vh]">
                            {options.map((option) => (
                                <CommandItem
                                    key={option.value}
                                    onSelect={() => {
                                        onChange(
                                            selectedStandardIds.includes(option.value)
                                                ? selectedStandardIds.filter(id => id !== option.value)
                                                : [...selectedStandardIds, option.value]
                                        )
                                        setOpen(true)
                                    }}
                                    className="flex items-center gap-2 md:py-2 py-4"
                                >
                                    <Check
                                        className={cn(
                                            'h-4 w-4 text-skyBlue',
                                            selectedStandardIds.includes(option.value)
                                                ? 'opacity-100'
                                                : 'opacity-0'
                                        )}
                                    />
                                    <span className="flex-grow">{option.label}</span>                     
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </Command>
                </PopoverContent>
            </Popover>
        </div>
    )
}