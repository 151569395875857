import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParam } from 'use-query-params'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import type { DataDownlink } from '@/data/admin/dataDownlink'
import useAdminDataDownlink from '@/hooks/useAdminDataDownlink'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'

import DeviceImeiAutocomplete from '../v2/selects/DeviceImeiAutocomplete'

const CreateUpdateDataDownlinkSchema = z.object({
  imei: z.string().length(15),
  fillValveTime: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number(),
  ),
  drainValveTime: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number(),
  ),
  balanceValveTime: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().optional().nullable(),
  ),
  dripLineValveTime: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number(),
  ),
  setAutoRegulate: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number(),
  ),
  setActiveSection: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number(),
  ),
  shouldFetchConfig: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number(),
  ),
  shouldReboot: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number(),
  ),
  manualFertigationRatio: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().optional().nullable(),
  ),
  desiredNitrogenPPM: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().optional().nullable(),
  ),
})

export type CreateUpdateDataDownlink = z.infer<
  typeof CreateUpdateDataDownlinkSchema
>

const getDefaultValues = (data?: DataDownlink): CreateUpdateDataDownlink => ({
  imei: data?.imei || '',
  fillValveTime: data?.fillValveTime || -1,
  drainValveTime: data?.drainValveTime || -1,
  dripLineValveTime: data?.dripLineValveTime || -1,
  balanceValveTime: data?.balanceValveTime,
  setAutoRegulate:
    typeof data?.setAutoRegulate === 'number' ? data.setAutoRegulate : -1,
  setActiveSection:
    typeof data?.setActiveSection === 'number' ? data.setActiveSection : -1,
  shouldFetchConfig:
    typeof data?.shouldFetchConfig === 'number' ? data.shouldFetchConfig : -1,
  shouldReboot: typeof data?.shouldReboot === 'number' ? data.shouldReboot : -1,
  desiredNitrogenPPM:
    typeof data?.desiredNitrogenPPM === 'number' ? data.desiredNitrogenPPM : -1,
})

function AddEditDataDownlinkModal() {
  const { t } = useTranslation(['admin', 'common'])
  const { toast } = useToast()
  const [id, setId] = useQueryParam('dataDownlinkId', StringParam)
  const { data, onCreate, onUpdate } = useAdminDataDownlink(id)
  const isOpen = Boolean(id)
  const isNew = id === 'new'

  const form = useForm<CreateUpdateDataDownlink>({
    resolver: zodResolver(CreateUpdateDataDownlinkSchema),
    defaultValues: getDefaultValues(data),
  })

  useEffect(() => {
    if (data) {
      form.reset({ ...getDefaultValues(data) })
    }
  }, [data])

  const handleSubmit = async (data: CreateUpdateDataDownlink) => {
    try {
      if (isNew) {
        await onCreate(data)
      } else {
        await onUpdate({ id: Number(id!), data })
      }
      toast({
        title: `Data downlink ${isNew ? 'created' : 'updated'}!`,
        className: successfulToastClassNames,
      })
      handleCloseAndReset()
    } catch (err) {
      toast({
        title: `Failed to ${isNew ? 'create' : 'update'} data downlink`,
        className: failureToastClassNames,
      })
    }
  }

  const handleCloseAndReset = () => {
    setId(null)
    form.reset({ ...getDefaultValues() })
  }

  const isSubmitting = form.formState.isSubmitting

  return (
    <Dialog open={isOpen} onOpenChange={handleCloseAndReset}>
      <DialogContent className="max-h-[90%] max-w-3xl overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-3xl text-black">
            {isNew
              ? t('data_downlink_modal.create_header')
              : t('data_downlink_modal.update_header')}
          </DialogTitle>
          <DialogDescription>
            {t('data_downlink_modal.description')}
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="my-4 flex max-w-full flex-col gap-2 overflow-x-hidden px-1 sm:px-2"
          >
            <FormField
              control={form.control}
              name="imei"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>{t('data_downlink_modal.imei')}</FormLabel>
                  <FormControl>
                    <div className="w-full">
                      <DeviceImeiAutocomplete
                        imei={field.value || ''}
                        onSelect={(val) => {
                          field.onChange(val)
                        }}
                      />
                      <FormMessage />
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
            <div className="mt-4">
              <DialogDescription>
                These are timer values in seconds. 0 means close, any value
                above 0 means leave the valve open for that many seconds. Leave
                the field at -1 or empty to do nothing
              </DialogDescription>
            </div>
            <div className="mt-4 flex flex-wrap gap-2 sm:flex-nowrap">
              <FormField
                control={form.control}
                name="fillValveTime"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>
                      {t('data_downlink_modal.fill_valve_time')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        type="number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="drainValveTime"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>
                      {t('data_downlink_modal.drain_valve_time')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        type="number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="dripLineValveTime"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>
                      {t('data_downlink_modal.drip_line_valve_time')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        type="number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="balanceValveTime"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>
                      {t('data_downlink_modal.balance_valve_time')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        type="number"
                        {...field}
                        value={field.value || ''}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="mt-4">
              <DialogDescription>
                If the Device uses fertigation you can override the desired
                Nitrogen concentration here. This will be converted to a
                fertigation ratio which tells the device how to regulate the
                water valve and the fertigation valve
              </DialogDescription>
            </div>
            <div className="flex flex-wrap gap-2 sm:flex-nowrap">
              <FormField
                control={form.control}
                name="desiredNitrogenPPM"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>
                      {t('data_downlink_modal.desired_nitrogen_ppm')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        type="number"
                        {...field}
                        value={field.value || ''}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="mt-4">
              <DialogDescription>
                These are true/false values. 0 means false, 1 means true. Leave
                the field at -1 to do nothing
              </DialogDescription>
            </div>
            <div className="flex flex-wrap gap-2 sm:flex-nowrap">
              <FormField
                control={form.control}
                name="setAutoRegulate"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>
                      {t('data_downlink_modal.should_auto_regulate')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        type="number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="setActiveSection"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>
                      {t('data_downlink_modal.set_active_section')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        type="number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex flex-wrap gap-2 sm:flex-nowrap">
              <FormField
                control={form.control}
                name="shouldFetchConfig"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>
                      {t('data_downlink_modal.should_fetch_config')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        type="number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="shouldReboot"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>
                      {t('data_downlink_modal.should_reboot')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        type="number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter className="mt-8">
              <Button variant="outline" onClick={handleCloseAndReset}>
                {t('modal.close_button', { ns: 'common' })}
              </Button>
              <Button type="submit" isLoading={isSubmitting}>
                {t('modal.save_button', { ns: 'common' })}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
export default AddEditDataDownlinkModal
